export const CHANNELLIST = [
  { channel: 'ANTB', channelName: '网商银行' },
  { channel: 'ANTB_V2', channelName: '网商银行2.0' },
  { channel: 'PSBC', channelName: '邮储银行' },
  { channel: 'ICBC', channelName: '工商银行（银企直联）' },
  { channel: 'ICBC_V2', channelName: '工商银行（聚富通）' },
  { channel: 'ICBC_V3', channelName: '工商银行（聚富通2.0）'},
  { channel: 'CEB', channelName: '光大银行（物流通）' },
  { channel: 'SPABANK', channelName: '平安银行' },
  { channel: 'YZT', channelName: '云直通' },
  { channel: 'SPAB_STAR', channelName: '平安财资' },
  { channel: 'IMRC', channelName: '内蒙古农信' },
  { channel: 'SPAB_LICENSE', channelName: '平安银行（牌照）' },
]
